<template>

    <form @submit.prevent="update" @keydown="form.onKeydown($event)">
      <!-- Invite Code -->
      <div class="form-group row">
        <label class="col-md-3 col-form-label text-md-right">{{ $t('invite.code') }}</label>
        <div class="col-md-7">
          <input v-model="form.code" :class="{ 'is-invalid': form.errors.has('code') }" class="form-control" type="text" name="code">
          <has-error :form="form" field="code" />
        </div>
      </div>

      <!-- Submit Button -->
      <div class="form-group row">
        <div class="col-md-9 ml-md-auto">
          <v-button :loading="form.busy" type="success">
            {{ $t('invite.validate') }}
          </v-button>
        </div>
      </div>
    </form>
</template>

<script>
import Form from 'vform'
import { mapGetters } from 'vuex'

export default {
  scrollToTop: false,

  metaInfo () {
    return { title: this.$t('settings') }
  },

  data: () => ({
    form: new Form({
      code: ''
    })
  }),

  computed: mapGetters({
    user: 'auth/user'
  }),

  methods: {
    async update () {
      const { data } = await this.form.post('/api/v1/invite/verify');

      this.$router.push({ name: 'accounts.church.create', params: { code: this.form.code, church: data.church}});
    }
  }
}
</script>
